import React from 'react';
import SEO from 'components/seo';
import { Segment } from 'components/segment/Segment';
import SocialButtons from 'components/social-buttons/SocialButtons';

export default () => (
  <>
    <SEO title="Demo Page" />

    <Segment>
      <h1>Demo Page for developement</h1>
      <SocialButtons></SocialButtons>
    </Segment>
  </>
);

import React, { PureComponent } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { Typography } from '@material-ui/core';

import FacebookLogin from 'react-facebook-login';


// const {GOOGLE_CLIENT_ID, FACEBOOK_APP_ID} = process.env;

export default class SocialButtons extends PureComponent{
    state = {
        user: null
    }

    responseGoogle(e) {
        console.log('google Response::', e);
if(e.profileObj){
    this.setState({
        user: e.profileObj
    });
}
        
    };

    logoutGoogle(){
        console.log('logoutGoogle');
        this.setState({
            user: null
        });
    }
    responseFacebook(e){
        console.log('responseFacebook::', e);
    }
    render(){
        const {user} = this.state;

        console.log('GOOGLE_CLIENT_ID', process.env.GOOGLE_CLIENT_ID, process.env.FACEBOOK_APP_ID)
        return (
            <>
            <FacebookLogin
    appId={`${process.env.FACEBOOK_APP_ID}`}
    autoLoad={true}
    fields="name,email,picture"
    callback={this.responseFacebook.bind(this)} />

            {!user &&<GoogleLogin
    clientId={`${process.env.GOOGLE_CLIENT_ID}`}
    buttonText="Login"
    onSuccess={this.responseGoogle.bind(this)}
    onFailure={this.responseGoogle.bind(this)}
    cookiePolicy={'single_host_origin'}
    isSignedIn={true}
  />}

{user && <GoogleLogout
      clientId={`${process.env.GOOGLE_CLIENT_ID}`}
      buttonText="Logout"
      onLogoutSuccess={this.logoutGoogle.bind(this)}
    />
}
{user && <><img src={user.imageUrl} /><Typography>{user.name}</Typography></>}
            </>
        )
    }
    

}

